import Vue from 'vue'
import VueRouter from 'vue-router'
import Store from '@/store/index'
import Secret from "@/utils/secret";


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: '首页',
    component: () => import('../views/Home.vue')
  },
  {
    path: '/companyProfile',
    name: '企业概况',
    component: () => import('../views/navRouter/companyProfile.vue')
  },
  {
    path: '/productSeries',
    name: '产品与技术',
    component: () => import('../views/navRouter/productSeries.vue')
  },
  {
    path: '/solution',
    name: '解决方案',
    component: () => import('../views/navRouter/solution.vue')
  },
  {
    path: '/pressCenter',
    name: '新闻中心',
    component: () => import('../views/navRouter/pressCenter.vue')
  },
  {
    path: '/talentRecruitment',
    name: '加入我们',
    component: () => import('../views/navRouter/talentRecruitment.vue')
  },
  {
    path: '/contactUs',
    name: '联系我们',
    component: () => import('../views/navRouter/contactUs.vue')
  },
  {
    path: '/productAndSolutionDetails',
    name: '产品系列和解决方案详情页',
    component: () => import('../views/details/productAndSolutionDetails.vue')
  },
  {
    path: '/pressCenterDetails',
    name: '新闻详情页',
    component: () => import('../views/details/pressCenterDetails.vue')
  },
]

const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {
  // console.log('to', to)
  // console.log('from', from)
  // console.log('next', next)
  // console.log('Store.getters.accessToken', Store.getters.accessToken)
  // console.log('Store.getters.sessionId', Store.getters.sessionId)

  if(!Store.getters.accessToken && !Store.getters.sessionId) {
    Secret.loadGenerateAccessToken()
  }
  next();
})

export default router
