import Vue from 'vue';
import axios from "axios";
import storage from '@/store/index'
import Secret from "@/utils/secret";

axios.default.withCredentials = false;
let _baseURL;
if(process.env.NODE_ENV === "development") {
  _baseURL = process.env.VUE_APP_BASE_API_WEBSITE;
} else if(process.env.NODE_ENV === "production") {
  _baseURL = USER_GATEWAY ? USER_TOMCAT ? VUE_APP_BASE_API_GATEWAY + 'website-rest' : '/website-rest' : VUE_APP_BASE_API_WEBSITE;
}

const Server = axios.create({
  baseURL: _baseURL,
  timeout: 120000
});

/*请求拦截器*/
Server.interceptors.request.use(request => requestIntercept(request), error => errorIntercept(error));
/*响应拦截器*/
Server.interceptors.response.use(response => responseIntercept(response), error => errorIntercept(error));
//请求拦截
const requestIntercept = request => {
  // console.log("request",request, request.url);

  request.headers['Content-Type'] ='application/x-www-form-urlencoded'

  if(request.method !== 'get') {
    request.headers['ra_token'] = Secret.raTokenFun()
  }
  if(sessionStorage.getItem('accessToken')){
    request.headers['access_token'] = sessionStorage.getItem('accessToken');
  }
  if(sessionStorage.getItem('sessionId')){
    request.headers['sessionid'] = sessionStorage.getItem('sessionId');
  }
  return request;
}
//响应拦截
const responseIntercept = response => {
  // console.log("response",response);
  return Promise.resolve(response.data);
}
//错误拦截
const errorIntercept = error => {
  console.log("error", error, error.response);
  if(error.response.status === 401 || error.response.status === 403) {
    Secret.loadGenerateAccessToken();
    Server(error.response.config)
  } else {
    if(error.response.data.message) { //接口返回错误信息
      Vue.prototype.$message.error(error.response.data.message);
    }else { //服务器提示
      Vue.prototype.$message.error(error.response.status+":"+error.response.statusText);
    }
  }

}
export default {
  Server,
}
