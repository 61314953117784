import request from "@/utils/request";
import qs from 'qs'

const _auth = {
  generateAccessToken: '/auth/generateAccessToken ',
}
const _page = {
  getResourceOfPage: '/page/getResourceOfPage',
  getArticleList: '/page/getArticleList',
  getJobList: '/page/getJobList',
  saveComment: '/page/saveComment',
  getArticleContent: '/page/getArticleContent'

}

export default {
  generateAccessToken: (params) => request.Server({url: _auth.generateAccessToken, method: 'post', data: qs.stringify(params)}),

  getResourceOfPage: (params) => request.Server({url: _page.getResourceOfPage, method: 'get', params}),
  getArticleList: (params) => request.Server({url: _page.getArticleList, method: 'get', params}),
  getJobList: (params) => request.Server({url: _page.getJobList, method: 'get', params}),
  saveComment: (params) => request.Server({url: _page.saveComment, method: 'post', data: qs.stringify(params)}),
  getArticleContent: (params) => request.Server({url: _page.getArticleContent, method: 'get', params}),

}
