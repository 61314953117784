import CryptoJS from 'crypto-js';
import {JSEncrypt} from "jsencrypt";
import { DateTime } from 'luxon';
import Store from '@/store/index'


/*
  * 对密码进行加密，传输给后台进行验证
  * @param {String}   word  需要加密的密码
  * @param {String}   keyStr  对密码加密的秘钥
  * @return {String}   加密的密文
  * */
const pubKey = `-----BEGIN PUBLIC KEY-----
MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEA1da7OMDIIuTZjoCyWpPd
9lta2qbGDrimtkvJvR1ctClHXaW9DnCEZJhwudHVVVfUhm6ZG8yhRS5u9oVCSIQ0
SafT37O0jhAwWDyJ7uXm6eDrQatBkGF9klXpeVifZJLQH5fe6S/SHymjVbbcIll/
qyvoeG7QAU93Ak+1QoJb2jWgCchFLctF6OnWEYFxVQLC58nNaptzCoavQhQ9Va5c
WtuSF15sI9aRNqytu2zdLiI0kw2DHau2q/nWiWXWtxLVTgrkcZm9chYhM61oClxA
5fdFsvplJm3iYbzJajA3Uio9EZH6DEApEacOFBSKnnf7cvaJiE0feuSnpByjItKS
HQIDAQAB
-----END PUBLIC KEY-----`
//加密
const encrypt = (word) => {
  let encrypt = new JSEncrypt();
  encrypt.setPublicKey(pubKey);
  let encrypted = encrypt.encrypt(word);
  let _encrypted = CryptoJS.enc.Base64.parse(encrypted);
  _encrypted = CryptoJS.enc.Hex.stringify(_encrypted);
  return _encrypted;
}
const raTokenFun = () => {
  let _len = 3, code = "";
  let random = new Array(0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R',
    'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'); //随机数
  for (let i=0; i<_len; i++) {
    let charIndex = Math.floor(Math.random() * 36); //取得随机数的索引
    code += random[charIndex]; //根据索引取得随机数加到code上
  }
  let str = code + new Date().getTime().toString();
  let str1=window.btoa(str);
  let str2 = CryptoJS.enc.Utf8.parse(str1);
  let hex = CryptoJS.enc.Hex.stringify(str2);
  return hex;
}

const ConvertToDateFun = (dateStr, format) => {
  const date = DateTime.fromISO(dateStr)
  return date.toFormat(format)
}

const loadCurrentDateFun = (format) => {
  return DateTime.local().toFormat(format)
}

const loadCurrentDateTimeFun = () => {
  return DateTime.now().valueOf()
}

const loadGenerateAccessToken = () => {
  api.generateAccessToken({data: encrypt(tokenString+loadCurrentDateTimeFun())}).then((res) => {
    if(res.code === 100) {
      Store.commit("SET_ACCESS_TOKEN", res.data.token);
      Store.commit("SET_SESSION_ID", res.data.sessionid);
    }else {
      this.$message.error(res.msg);
    }
  }).catch((err) => {
    if(err.msg) {
      this.$message.error(err.msg);
    }
  });
}

export default {
  encrypt,
  raTokenFun,
  ConvertToDateFun,
  loadCurrentDateFun,
  loadCurrentDateTimeFun,
  loadGenerateAccessToken
}
