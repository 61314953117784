const route = {
  state: {
    currentRoute: '',
    secondaryRoutingIndex: '',
    articlesId: null,
    pageRefreshed: ''
  },
  mutations: {
    SET_CURRENT_ROUTE: (state, route) => {
      state.currentRoute = route;
      sessionStorage.setItem('currentRoute', route);
    },
    SET_SECONDARY_ROUTING_INDEX: (state, index) => {
      state.secondaryRoutingIndex = index;
      sessionStorage.setItem('secondaryRoutingIndex', index);
    },
    SET_ARTICLES_ID: (state, id) => {
      state.articlesId = id;
      sessionStorage.setItem('articlesId', id);
    },
    SET_PAGE_REFRESHED: (state, refresh) => {
      state.pageRefreshed = refresh;
      sessionStorage.setItem('pageRefreshed', refresh);
    },
  },
  actions: {

  }
}

export default route
