const getters = {
  // user : 用户相关
  accessToken: state => {
    return state.route.accessToken === '' ? state.route.accessToken : sessionStorage.getItem('accessToken');
  },
  sessionId: state => {
    return state.route.sessionId === '' ? state.route.sessionId : sessionStorage.getItem('sessionId');
  },
  // route : 路由相关
  currentRoute: state => {
    return state.route.currentRoute ? state.route.currentRoute : sessionStorage.getItem('currentRoute');
  },
  secondaryRoutingIndex: state => {
    return state.route.secondaryRoutingIndex ? state.route.secondaryRoutingIndex : sessionStorage.getItem('secondaryRoutingIndex');
  },
  articlesId: state => {
    return state.route.articlesId ? state.route.articlesId : sessionStorage.getItem('articlesId');
  },
  pageRefreshed: state => {
    return state.route.pageRefreshed ? state.route.pageRefreshed : sessionStorage.getItem('pageRefreshed');
  },
};

export default getters;
