<template>
  <div id="header" class="headers">
    <div class="nav_wp">
      <div class="wp_logo">
        <img alt="logo" class="loginLogo" src="@/assets/image/logo.png" />
      </div>
      <div class="wp_nav">
        <el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal"  @select="handleSelectMenuItemFun">
          <el-menu-item index="1">首页</el-menu-item>
          <el-submenu index="2">
            <template slot="title">企业概况</template>
            <el-menu-item index="2-1">关于我们</el-menu-item>
            <el-menu-item index="2-2">我们的优势</el-menu-item>
          </el-submenu>
          <el-submenu index="3">
            <template slot="title">产品与技术</template>
            <el-menu-item index="3-1">技术概况</el-menu-item>
            <el-menu-item index="3-2">产品系列</el-menu-item>
            <el-menu-item index="3-3">客户说</el-menu-item>
          </el-submenu>
          <el-submenu index="4">
            <template slot="title">解决方案</template>
            <el-menu-item index="4-1">电力数据分析</el-menu-item>
            <el-menu-item index="4-2">节能增效</el-menu-item>
            <el-menu-item index="4-3">用电安全</el-menu-item>
            <el-menu-item index="4-4">精细化运维</el-menu-item>
          </el-submenu>
          <el-menu-item index="5">加入我们</el-menu-item>
          <el-submenu index="6">
            <template slot="title">新闻中心</template>
            <el-menu-item index="6-1">公司新闻</el-menu-item>
            <el-menu-item index="6-2">行业新闻</el-menu-item>
          </el-submenu>
          <el-submenu index="7">
            <template slot="title">联系我们</template>
            <el-menu-item index="7-1">联系方式</el-menu-item>
            <el-menu-item>
              <a
                style="text-decoration: none;color: inherit;"
                href="http://mail.transenergy-tech.com/"
                target="_blank">企业邮箱</a>
            </el-menu-item>
          </el-submenu>
        </el-menu>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'

export default {
  name: "headers",
  data() {
    return {
      activeIndex: '',
    }
  },
  created() {
    this.activeIndex = this.loadRoutingIndexFun(this.currentRoute)
  },
  computed: {
    ...mapGetters(['currentRoute'])
  },
  watch: {
    currentRoute: {
      handler(n,o) {
        this.activeIndex = this.loadRoutingIndexFun(n)
      },
      immediate: true
    },
  },
  methods: {
    handleSelectMenuItemFun(path, keyPath) {
      if(path === null) {
        console.log("不做处理")
      } else {
        if(keyPath.length === 2) {
          this.$store.commit('SET_SECONDARY_ROUTING_INDEX', keyPath[1])
        } else {
          this.$store.commit('SET_SECONDARY_ROUTING_INDEX', '')
        }
        if(this.activeIndex !== keyPath[0]) {
          this.activeIndex = keyPath[0]
          this.$store.commit('SET_CURRENT_ROUTE', this.loadRoutingPathFun(keyPath[0]))
          this.$router.push({path: this.loadRoutingPathFun(keyPath[0])})
        }

      }
    },
    loadRoutingPathFun(index) {
      switch (index) {
        case '1':
          return '/';
        case '2':
          return '/companyProfile';
        case '3':
          return '/productSeries';
        case '4':
          return '/solution';
        case '5':
          return '/talentRecruitment';
        case '6':
          return '/pressCenter';
        case '7':
          return '/contactUs';
      }
    },
    loadRoutingIndexFun(path) {
      switch (path) {
        case '/':
          return '1';
        case '/companyProfile':
          return '2';
        case '/productSeries':
          return '3';
        case '/solution':
          return '4';
        case '/talentRecruitment':
          return '5';
        case '/pressCenter':
          return '6';
        case '/contactUs':
          return '7';
      }
    }

  }
}
</script>

<style scoped lang="scss">
.headers{
  position: absolute;
  top: 0;
  z-index: 10;
  width: 100%;
  height: 80px;
  min-width: 1200px;
  margin: 0px auto;
  overflow: visible;
  background: rgb(255, 255, 255);
  box-shadow: rgba(0, 0, 0, 0.15) 0 2px 5px 0;
  .nav_wp{
    width: 1200px;
    padding: 0;
    margin: 0 auto;
    height: 80px;
    overflow: hidden;
    .wp_logo{
      float: left;
      margin-top: 8px;
    }
    .wp_nav {
      float: right;
      ::v-deep .el-menu {
        background-color: rgba(255,255,255,0);
      }
      ::v-deep .el-menu.el-menu--horizontal {
        border-bottom: none;
      }
      ::v-deep .el-menu--horizontal>.el-menu-item.is-active{
        border-bottom: none;
        color: #333333;
        //color: rgba(42, 130, 228, 1);
        //color: rgba(255,255,255,1);
        //background: #FFFFFF;
      }
      ::v-deep .el-menu--horizontal>.el-submenu.is-active .el-submenu__title {
        border-bottom: 0;
      }
      ::v-deep .el-menu--horizontal>.el-menu-item,
      ::v-deep .el-menu--horizontal>.el-submenu .el-submenu__title{
        height: 80px;
        line-height: 80px;
        color: #333333;
        border-radius: 4px;
      }
      ::v-deep .el-menu--horizontal .el-menu-item:not(.is-disabled):focus,
      ::v-deep .el-menu--horizontal .el-menu-item:not(.is-disabled):hover {
        color: #333333;
        //color: rgba(42, 130, 228, 1);
        //color: rgba(255,255,255,1);
      }
    }
  }
}
</style>
