import Vue from 'vue'
import router from './router'
import store from './store'
import api from "@/api/index.js";
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css'
import 'fullpage.js/vendors/scrolloverflow'
import "./assets/vueFullPage/fullpage.min.css"
import VueFullPage from "./assets/vueFullPage/vue-fullpage"
import App from './App.vue'

const resAddressPrefix = process.env.NODE_ENV === 'development' ? process.env.VUE_APP_BASE_API_WEBSITE : (USER_GATEWAY ? USER_TOMCAT ? VUE_APP_BASE_API_GATEWAY + 'website-rest/' : '/website-rest/' : VUE_APP_BASE_API_WEBSITE)

Vue.use(ElementUI);
Vue.use(VueFullPage)
Vue.config.productionTip = false;
window.api = api;
window.resAddressPrefix = resAddressPrefix;


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
