const user = {
  state: {
    accessToken: '',
    sessionId: '',
  },
  mutations: {
    SET_ACCESS_TOKEN: (state, token) => {
      state.accessToken = token;
      sessionStorage.setItem('accessToken', token);
    },
    SET_SESSION_ID: (state, id) => {
      state.sessionId = id;
      sessionStorage.setItem('sessionId', id);
    },
  },
  actions: {
  }
}

export default user
