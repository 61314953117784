<template>
  <div id="app">
    <el-container>
      <el-header :height="headerHeight"><Headers></Headers></el-header>
      <el-main><router-view/></el-main>
    </el-container>
    <el-backtop :bottom="40" :right="40">
      <div class="backTopIcon"><i class="el-icon-caret-top"></i></div>
    </el-backtop>
  </div>
</template>
<script>
import Headers from "@/components/header.vue"
export default {
  components: {
    Headers,
  },
  data() {
    return {
      headerHeight: '0px',
    }
  },
  watch:{
    $route(to, from) {
      this.headerHeight = to.path === '/' ? '0px' : '80px'
      // console.log(from.path);
    }
  },
}
</script>

<style scoped lang="scss">
html,
body,
#app {
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
  font-family: "微软雅黑";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  ::v-deep .el-container {
    padding: 0;
    .el-main {
      padding: 0;
    }
  }
  ::v-deep .el-header {
    //height: 0px!important;
    padding: 0;
  }
}
.backTopIcon {
  background-color: #fff;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  color: #409eff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  box-shadow: 0 0 6px rgba(0,0,0,.12);
  cursor: pointer;
  z-index: 5;
}
</style>
<style lang="scss">
html,
body,
#app {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}
</style>
